// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_ORDERS_URL } from "../constants"

export const getListOrders = createAsyncThunk(
    "orders/getListOrders",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate,
        user,
        branch,
        company,
        status,
        type,
        paymentType,
        paymentMethod,
        deliveryType,
        totalFrom,
        totalTo,
        excludeOpen,
        createdFrom,
        createdTo
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (user) {
            queryParams += `&user=${user}`
        }
        if (branch) {
            queryParams += `&branch=${branch}`
        }
        if (company) {
            queryParams += `&company=${company}`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (type) {
            queryParams += `&type=${type}`
        }
        if (paymentType) {
            queryParams += `&paymentType=${paymentType}`
        }
        if (paymentMethod) {
            queryParams += `&paymentMethod=${paymentMethod}`
        }
        if (deliveryType) {
            queryParams += `&deliveryType=${deliveryType}`
        }
        if (totalFrom) {
            queryParams += `&totalFrom=${totalFrom}`
        }
        if (totalTo) {
            queryParams += `&totalTo=${totalTo}`
        }
        if (createdFrom) {
            queryParams += `&createdFrom=${createdFrom}`
        }
        if (createdTo) {
            queryParams += `&createdTo=${createdTo}`
        }
        if (excludeOpen) {
            queryParams += `&excludeOpen=true`
        }
        const response = await axios.get(`${API_ORDERS_URL}?${queryParams}`)
        return response.data
    }
)

export const getOrderById = createAsyncThunk(
    "orders/getOrderById",
    async (id, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_ORDERS_URL}/${id}`
        )
        return response.data
    }
)

export const storeOrder = createAsyncThunk(
    "orders/storeOrder",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_ORDERS_URL}`,
            data
        )
        return response.data
    }
)

export const updateOrder = createAsyncThunk(
    "orders/updateOrder",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_ORDERS_URL}/${data._id}`,
            data
        )
        return response.data
    }
)

export const getOrderTotals = createAsyncThunk(
    "orders/getOrderTotals",
    async ({
        status,
        user,
        branch,
        userRecommended
    } = {}) => {

        let queryParams = `status=${status || 'delivered'}`
        if (user) {
            queryParams += `&user=${user}`
        }
        if (branch) {
            queryParams += `&branch=${branch}`
        }
        if (userRecommended) {
            queryParams += `&userRecommended=${userRecommended}`
        }
        const response = await axios.get(`${API_ORDERS_URL}/get/totals?${queryParams}`)
        return response.data
    }
)

const getPointsTotal = (personal, referred, employee) => {
    return parseFloat(personal || 0) + parseFloat(referred || 0) + parseFloat(employee || 0)
}

export const ordersSlice = createSlice({
    name: "orders",
    initialState: {
        list: [],
        totalPages: 1,
        loading: true,
        hasNextPage: false,
        pagingCounter: 0,
        totalDocs: 0,
        branch: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListOrders.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListOrders.fulfilled, (state, action) => {
                const data = action.payload.docs || action.payload
                const sums = {
                    products: 0,
                    sales: 0,
                    commission: 0,
                    personal: 0,
                    referred: 0,
                    employee: 0,
                    pointsTotal: 0
                }
                const finalData = data.map((el) => {
                    const pointsTotal = getPointsTotal(el.totalPointsPersonal, el.totalPointsReferred, el.totalPointsEmployee)
                    el['pointsTotal'] = pointsTotal
                    sums.products += el.quantity
                    sums.sales += el.total
                    sums.commission += el.totalParticipations
                    sums.personal += el.totalPointsPersonal
                    sums.referred += el.totalPointsReferred
                    sums.employee += el.totalPointsEmployee
                    sums.pointsTotal += el.pointsTotal
                    return el
                })
                //state.list = action.payload.docs || action.payload
                state.list = finalData
                state.sumData = sums                
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
    }
})

export default ordersSlice.reducer
