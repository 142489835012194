// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_CLIENTS_URL } from "../constants"

export const getClientById = createAsyncThunk(
    "clients/getClientById",
    async (id, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_CLIENTS_URL}/${id}`
        )
        return response.data
    }
)

export const storeClient = createAsyncThunk(
    "clients/storeClient",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_CLIENTS_URL}`,
            data
        )
        return response.data
    }
)

export const updateClient = createAsyncThunk(
    "clients/updateClient",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_CLIENTS_URL}/${data._id}`,
            data
        )
        return response.data
    }
)

export const clientsSlice = createSlice({
    name: "clients",
    initialState: {},
    reducers: {}
})

export default clientsSlice.reducer
