// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_CASH_REGISTERS_URL } from "../constants"

export const getListCashRegisters = createAsyncThunk(
    "cashRegisters/getListCashRegisters",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate,
        branch,
        company,
        status,
        statusProofOfPayment,
        ordersFrom,
        ordersTo
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (branch) {
            queryParams += `&branch=${branch}`
        }
        if (company) {
            queryParams += `&company=${company}`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (ordersFrom) {
            queryParams += `&ordersFrom=${ordersFrom}`
        }
        if (ordersTo) {
            queryParams += `&ordersTo=${ordersTo}`
        }
        if (statusProofOfPayment) {
            queryParams += `&statusProofOfPayment=${statusProofOfPayment}`
        }
        const response = await axios.get(`${API_CASH_REGISTERS_URL}?${queryParams}`)
        return response.data
    }
)

export const getCashRegisterById = createAsyncThunk(
    "cashRegisters/getCashRegisterById",
    async (id, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_CASH_REGISTERS_URL}/${id}`
        )
        return response.data
    }
)

export const storeCashRegister = createAsyncThunk(
    "cashRegisters/storeCashRegister",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_CASH_REGISTERS_URL}`,
            data
        )
        return response.data
    }
)

export const updateCashRegister = createAsyncThunk(
    "cashRegisters/updateCashRegister",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_CASH_REGISTERS_URL}/${data._id}`,
            data
        )
        return response.data
    }
)

export const uploadDoc = createAsyncThunk(
    "cashRegisters/uploadDoc",
    async (data, { dispatch, getState }) => {

        const formData = new FormData()
        formData.append('files', data.file[0])
        formData.append('descriptions', data.dataToSend.descriptions)
        formData.append('observations', data.dataToSend.observations)
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const response = await axios.post(
            `${API_CASH_REGISTERS_URL}/uploads/${data.dataToSend.cashRegisterId}/${data.dataToSend.type}/${data.dataToSend.section}`,
            formData,
            axiosConfig
        )
        return response.data
    }
)

export const cashRegistersSlice = createSlice({
    name: "cashRegisters",
    initialState: {
        list: [],
        totalPages: 1,
        loading: true,
        hasNextPage: false,
        pagingCounter: 0,
        totalDocs: 0
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListCashRegisters.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListCashRegisters.fulfilled, (state, action) => {
                const data = action.payload.docs || action.payload
                const sums = {
                    total: 0,
                    commissions: 0,
                    totalPayment: 0
                }
                const finalData = data.map((el) => {
                    sums.total += el.total
                    sums.commissions += el.totalCommissions
                    sums.totalPayment += el.totalPayment
                    return el
                })
                //state.list = action.payload.docs || action.payload
                state.list = finalData
                state.sumData = sums
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
    }
})

export default cashRegistersSlice.reducer
