// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_PRODUCTS_URL } from "../constants"

export const getListProductsByCompany = createAsyncThunk(
  "products/getListProductsByCompany",
  async ({
    page = 1,
    limit = 20,
    search,
    status,
    company,
    groups,
    section
  } = {}) => {

    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (status) {
      queryParams += `&status=${status}`
    }
    if (company) {
      queryParams += `&company=${company}`
    }
    if (groups) {
      queryParams += `&groups=${groups}`
    }
    if (section) {
      queryParams += `&section=${section}`
    }
    const response = await axios.get(`${API_PRODUCTS_URL}?${queryParams}`)
    return response.data
  }
)

export const storeProduct = createAsyncThunk(
  "products/storeProduct",
  async (data, { dispatch, getState }) => {
    const response = await axios.post(
      `${API_PRODUCTS_URL}/web`,
      data
    )
    return response.data
  }
)

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (data, { dispatch, getState }) => {
    const response = await axios.put(
      `${API_PRODUCTS_URL}/web/${data._id}`,
      data
    )
    return response.data
  }
)

export const uploadCoverImage = createAsyncThunk(
  "products/uploadCoverImage",
  async (data, { dispatch, getState }) => {

    const formData = new FormData()
    formData.append('files', data.file[0])
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_PRODUCTS_URL}/upload/cover-image/${data.productId}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const removeImage = createAsyncThunk(
  "products/removeImage",
  async (data, { dispatch, getState }) => {
    const response = await axios.delete(
      `${API_PRODUCTS_URL}/images/remove/${data.productId}/${data.index}/${data.section}`
    )
    return response.data
  }
)

export const uploadImageGallery = createAsyncThunk(
  "products/uploadImageGallery",
  async (data, { dispatch, getState }) => {
    const formData = new FormData()
    formData.append('files', data.file[0])
    formData.append('description', data.description)
    formData.append('isEdit', data.isEdit)
    const axiosConfig = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.post(
      `${API_PRODUCTS_URL}/upload/image-gallery/${data.productId}/${data.index}`,
      formData,
      axiosConfig
    )
    return response.data
  }
)

export const updateImageDescription = createAsyncThunk(
  "products/updateImageDescription",
  async (data, { dispatch, getState }) => {
    const response = await axios.put(
      `${API_PRODUCTS_URL}/update/image-gallery/description/${data.productId}/${data.index}`,
      data
    )
    return response.data
  }
)

const _STATUS_OPTIONS = [
  { value: 'active', label: 'ACTIVO' },
  { value: 'inactive', label: 'INACTIVO' }
]

const initProductData = {
  title: "",
  description: "",
  coverImage: "",
  images: [],
  price: "",
  ivaApplies: null,
  discountPorcent: "",
  group: null,
  variants: [],
  refundApplies: 'no',
  expressApplies: 'no',
  refundConditions: {
    time: '',
    cost: '',
    text: ''
  },
  deliveryTimeAprox: {
    status: _STATUS_OPTIONS[1],
    value: ''
  },
  participations: {
    status: _STATUS_OPTIONS[0],
    value: '',
    iva: false
  },
  percentagePointsPersonal: {
    status: _STATUS_OPTIONS[0],
    value: ''
  },
  percentagePointsReferred: {
    status: _STATUS_OPTIONS[0],
    value: ''
  },
  percentagePointsEmployee: {
    status: _STATUS_OPTIONS[0],
    value: ''
  },
  needApprove: false,
  _id: null,
  status: 'temp'
}

export const productsSlice = createSlice({
  name: "products",
  initialState: {
    list: [],
    totalPages: 1,
    loading: true,
    hasNextPage: false,
    pagingCounter: 0,
    totalDocs: 0,
    product: initProductData
  },
  reducers: {
    handleGetProductData: (state, action) => {
      state.product = action.payload || initProductData
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getListProductsByCompany.pending, (state, action) => {
        state.loading = true
      })
      .addCase(getListProductsByCompany.fulfilled, (state, action) => {
        console.log(`LIST`, action.payload.docs)
        state.list = action.payload.docs
        state.totalPages = action.payload.totalPages
        state.hasNextPage = action.payload.hasNextPage
        state.pagingCounter = action.payload.pagingCounter
        state.totalDocs = action.payload.totalDocs
        state.loading = false
      })
  }
})

export const { handleGetProductData } = productsSlice.actions

export default productsSlice.reducer
