// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_USERS_URL } from "../constants"

export const getListUsers = createAsyncThunk(
    "users/getListUsers",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate,
        status,
        roles,
        type,
        gender,
        ageFrom,
        ageTo,
        amountFrom,
        amountTo
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (roles) {
            queryParams += `&roles=${roles}`
        }
        if (type) {
            queryParams += `&type=${type}`
        }
        if (gender) {
            queryParams += `&gender=${gender}`
        }
        if (ageFrom) {
            queryParams += `&ageFrom=${ageFrom}`
        }
        if (ageTo) {
            queryParams += `&ageTo=${ageTo}`
        }
        if (amountFrom) {
            queryParams += `&amountFrom=${amountFrom}`
        }
        if (amountTo) {
            queryParams += `&amountTo=${amountTo}`
        }
        const response = await axios.get(`${API_USERS_URL}?${queryParams}`)
        return response.data
    }
)

export const getListUsersAsClients = createAsyncThunk(
    "users/getListUsersAsClients",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate,
        status,
        roles
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (roles) {
            queryParams += `&roles=${roles}`
        }
        const response = await axios.get(`${API_USERS_URL}?${queryParams}`)
        return response.data
    }
)

export const getListUsersReferrals = createAsyncThunk(
    "users/getListUsersReferrals",
    async ({
        page = 1,
        limit = 20,
        noPaginate,
        search,
        status,
        userRecommended,
        section
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (userRecommended) {
            queryParams += `&userRecommended=${userRecommended}`
        }
        if (section) {
            queryParams += `&section=${section}`
        }
        const response = await axios.get(`${API_USERS_URL}?${queryParams}`)
        return response.data
    }
)

export const getUserById = createAsyncThunk(
    "users/getUserById",
    async (id, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_USERS_URL}/${id}`
        )
        return response.data
    }
)

export const checkUserExists = createAsyncThunk(
    "users/checkUserExists",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_USERS_URL}/check/exists`,
            data
        )
        return response.data
    }
)

export const storeUser = createAsyncThunk(
    "users/storeUser",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_USERS_URL}`,
            data
        )
        return response.data
    }
)

export const updateUser = createAsyncThunk(
    "users/updateUser",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_USERS_URL}/${data._id}`,
            data
        )
        return response.data
    }
)

export const attachFiles = createAsyncThunk(
    "users/attachFiles",
    async (data, { dispatch, getState }) => {

        const formData = new FormData()
        formData.append('files', data.file[0])
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const response = await axios.post(
            `${API_USERS_URL}/upload/files/${data.dataToSend.userId}`,
            formData,
            axiosConfig
        )
        return response.data
    }
)

export const removeFile = createAsyncThunk(
    "users/removeFile",
    async (data, { dispatch, getState }) => {
        const response = await axios.delete(
            `${API_USERS_URL}/files/remove/${data.userId}/${data.index}`
        )
        return response.data
    }
)

export const removeUser = createAsyncThunk(
    "users/removeUser",
    async (data, { dispatch, getState }) => {
        const response = await axios.delete(
            `${API_USERS_URL}/${data.userId}`
        )
        return response.data
    }
)

export const getUserByInternalId = createAsyncThunk(
    "users/getUserByInternalId",
    async (internalId, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_USERS_URL}/internalId/${internalId}`
        )
        return response.data
    }
)

export const updateCompanyActiveWeb = createAsyncThunk(
    "users/updateCompanyActiveWeb",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_USERS_URL}/company-active-web/${data._id}`,
            data
        )
        return response.data
    }
)

export const getListUsersAsOwners = createAsyncThunk(
    "users/getListUsersAsOwners",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate,
        status,
        branch,
        onlyOwners
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (onlyOwners) {
            queryParams += `&onlyOwners=true`
        }
        if (branch) {
            queryParams += `&branch=${branch}`
        }
        const response = await axios.get(`${API_USERS_URL}?${queryParams}`)
        return response.data
    }
)

const getPointsTotal = (personal, referred, employee) => {
    return parseFloat(personal || 0) + parseFloat(referred || 0) + parseFloat(employee || 0)
}

const getPointsAvailable = (pointsTotal, totalPointsUsed) => {
    const availables = parseFloat(pointsTotal || 0) - parseFloat(totalPointsUsed || 0)
    return availables || 0
}

export const usersSlice = createSlice({
    name: "users",
    initialState: {
        list: [],
        totalPages: 1,
        loading: true,
        hasNextPage: false,
        pagingCounter: 0,
        totalDocs: 0,
        branchName: null,
        listReferrals: [],
        sumData: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListUsers.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListUsers.fulfilled, (state, action) => {
                const data = action.payload.docs || action.payload
                const sums = {
                    orders: 0,
                    amount: 0,
                    personal: 0,
                    referred: 0,
                    employee: 0,
                    pointsTotal: 0
                }
                const finalData = data.map((el) => {
                    const pointsTotal = getPointsTotal(el.extras.totalPointsPersonal, el.extras.totalPointsReferred, el.extras.totalPointsEmployee)
                    el['pointsTotal'] = pointsTotal
                    const pointsAvailable = getPointsAvailable(pointsTotal, el.extras.totalPointsUsed)
                    el['pointsAvailable'] = pointsAvailable
                    el['usedPoints'] = el.extras.totalPointsUsed
                    sums.orders += el.extras.totalOrders
                    sums.amount += el.extras.totalAmount
                    sums.personal += el.extras.totalPointsPersonal
                    sums.referred += el.extras.totalPointsReferred
                    sums.employee += el.extras.totalPointsEmployee
                    sums.pointsTotal += el.pointsTotal
                    return el
                })
                console.log(`FINAL DATA LIST`, finalData)
                //state.list = action.payload.docs || action.payload
                state.list = finalData
                state.sumData = sums
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
            .addCase(getListUsersReferrals.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListUsersReferrals.fulfilled, (state, action) => {
                const data = action.payload.docs || action.payload
                const sums = {
                    orders: 0,
                    amount: 0,
                    referred: 0
                }
                data.map((el) => {
                    sums.orders += el.extras.totalOrders
                    sums.amount += el.extras.totalAmount
                    sums.referred += el.totalPointsReferredToRecommended
                    //return el
                })
                state.listReferrals = action.payload.docs || action.payload
                state.sumData = sums
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
    }
})

export default usersSlice.reducer
