import { API_AUTH_URL } from "../../../constants"

// ** Auth Endpoints
export default {
  loginEndpoint: API_AUTH_URL,
  registerEndpoint: "/jwt/register",
  refreshEndpoint: `${API_AUTH_URL}/refresh`,
  logoutEndpoint: "/jwt/logout",

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken"
}
