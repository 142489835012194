// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_STATES_URL } from "../constants"

export const getListStates = createAsyncThunk(
  "states/getListStates",
  async ({
    page = 1,
    limit = 10,
    search
  } = {}) => {

    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }

    const response = await axios.get(`${API_STATES_URL}?${queryParams}`)
    return response.data
  }
)

export const statesSlice = createSlice({
  name: "states",
  initialState: {},
  reducers: {}
})

export default statesSlice.reducer
