// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_LOCATIONS_URL } from "../constants"

export const getListLocations = createAsyncThunk(
    "locations/getListLocations",
    async ({
        page = 1,
        limit = 20,
        search,
        branch,
        section,
        status,
        noPaginate
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (branch) {
            queryParams += `&branch=${branch}`
        }
        if (section) {
            queryParams += `&section=${section}`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        const response = await axios.get(`${API_LOCATIONS_URL}?${queryParams}`)
        return response.data
    }
)

export const storeLocation = createAsyncThunk(
    "locations/storeLocation",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_LOCATIONS_URL}`,
            data
        )
        return response.data
    }
)

export const updateLocation = createAsyncThunk(
    "locations/updateLocation",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_LOCATIONS_URL}/${data._id}`,
            data
        )
        return response.data
    }
)

export const removeLocation = createAsyncThunk(
    "locations/removeLocation",
    async (id, { dispatch, getState }) => {
        const response = await axios.delete(
            `${API_LOCATIONS_URL}/${id}`
        )
        return response.data
    }
)

export const locationsSlice = createSlice({
    name: "locations",
    initialState: {
        list: [],
        totalPages: 1,
        loading: true,
        hasNextPage: false,
        pagingCounter: 0,
        totalDocs: 0
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListLocations.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListLocations.fulfilled, (state, action) => {
                state.list = action.payload.docs
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
    }
})

export default locationsSlice.reducer
