// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_NOTIFICATIONS_URL } from "../constants"

export const getListNotifications = createAsyncThunk(
    "notifications/getListNotifications",
    async ({
        page = 1,
        limit = 50,
        noPaginate = true,
        branch,
        notificationType,
        status,
        user,
        company
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (branch) {
            queryParams += `&branch=${branch}`
        }
        if (notificationType) {
            queryParams += `&notificationType=${notificationType}`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (user) {
            queryParams += `&user=${user}`
        }
        if (company) {
            queryParams += `&company=${company}`
        }
        const response = await axios.get(`${API_NOTIFICATIONS_URL}?${queryParams}`)
        return response.data
    }
)

export const getListNotificationsUser = createAsyncThunk(
    "notifications/getListNotificationsUser",
    async ({
        page = 1,
        limit = 50,
        noPaginate = true,
        notificationType = 'user',
        status,
        user
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (notificationType) {
            queryParams += `&notificationType=${notificationType}`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (user) {
            queryParams += `&user=${user}`
        }
        const response = await axios.get(`${API_NOTIFICATIONS_URL}?${queryParams}`)
        return response.data
    }
)

export const storeNotification = createAsyncThunk(
    "notifications/storeNotification",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_NOTIFICATIONS_URL}`,
            data
        )
        return response.data
    }
)

export const removeNotification = createAsyncThunk(
    "notifications/removeNotification",
    async (id, { dispatch, getState }) => {
        const response = await axios.delete(
            `${API_NOTIFICATIONS_URL}/${id}`
        )
        return response.data
    }
)

export const updateManyNotifications = createAsyncThunk(
    "notifications/updateManyNotifications",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_NOTIFICATIONS_URL}/many/company/${data.type}`,
            data
        )
        return response.data
    }
)

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState: {
        list: [],
        totalPages: 1,
        loading: true,
        hasNextPage: false,
        pagingCounter: 0,
        totalDocs: 0,
        notis: null, // company
        notisUser: null // user
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListNotifications.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListNotifications.fulfilled, (state, action) => {
                console.log(`EXECUTE REDUX NOTIFICATIONS COMPANY`)
                const data = action.payload
                const notis = {
                    total: 0,
                    productsApprove: 0,
                    productsToApprove: 0,
                    ordersToDelivery: 0,
                    newReferrals: 0,
                    cashRegistersPaid: 0,
                    cashRegistersToApprove: 0,
                    productsToApproveArry: 0,
                    cashRegistersToApproveArry: 0
                }
                if (data && data.length > 0) {
                    const productsApproveCount = data.filter((obj) => (obj.notificationModule === 'products' && obj.notificationAction === 'approve')).length
                    const productsToApproveCount = data.filter((obj) => (obj.notificationModule === 'products' && obj.notificationAction === 'toApprove')).length
                    const ordersToDeliveryCount = data.filter((obj) => (obj.notificationModule === 'orders' && obj.notificationAction === 'toDelivery')).length
                    const newReferralsCount = data.filter((obj) => (obj.notificationModule === 'referrals' && obj.notificationAction === 'newData')).length
                    const cashRegistersPaidCount = data.filter((obj) => (obj.notificationModule === 'cashRegisters' && obj.notificationAction === 'paid')).length
                    const cashRegistersToApproveCount = data.filter((obj) => (obj.notificationModule === 'cashRegisters' && obj.notificationAction === 'toApprove')).length
                    const totalCount = parseInt(productsToApproveCount) + parseInt(ordersToDeliveryCount) + parseInt(productsApproveCount) + parseInt(newReferralsCount) + parseInt(cashRegistersPaidCount) + parseInt(cashRegistersToApproveCount)
                    notis.total = totalCount
                    notis.productsApprove = productsApproveCount
                    notis.productsToApprove = productsToApproveCount
                    notis.ordersToDelivery = ordersToDeliveryCount
                    notis.newReferrals = newReferralsCount
                    notis.cashRegistersPaid = cashRegistersPaidCount
                    notis.cashRegistersToApprove = cashRegistersToApproveCount
                    const productsToApproveArry = data.filter((obj) => (obj.notificationModule === 'products' && obj.notificationAction === 'toApprove'))
                    notis.productsToApproveArry = productsToApproveArry
                    const cashRegistersToApproveArry = data.filter((obj) => (obj.notificationModule === 'cashRegisters' && obj.notificationAction === 'toApprove'))
                    notis.cashRegistersToApproveArry = cashRegistersToApproveArry
                }
                state.list = data
                state.notis = notis
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
            .addCase(getListNotificationsUser.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListNotificationsUser.fulfilled, (state, action) => {
                console.log(`EXECUTE REDUX NOTIFICATIONS USER`)
                const data = action.payload
                const notisUser = {
                    total: 0,
                    newReferrals: 0
                }
                if (data && data.length > 0) {
                    const newReferralsCount = data.filter((obj) => (obj.notificationModule === 'referrals' && obj.notificationAction === 'newData')).length
                    const totalCount = parseInt(newReferralsCount)
                    notisUser.total = totalCount
                    notisUser.newReferrals = newReferralsCount
                }
                state.list = data
                state.notisUser = notisUser
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
    }
})

export default notificationsSlice.reducer
