// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_COMPANIES_URL } from "../constants"

export const getListCompanies = createAsyncThunk(
    "companies/getListCompanies",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate,
        branch,
        status,
        type,
        section,
        businessLine,
        owner,
        salesFrom,
        salesTo
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (branch) {
            queryParams += `&branch=${branch}`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (type) {
            queryParams += `&type=${type}`
        }
        if (section) {
            queryParams += `&section=${section}`
        }
        if (businessLine) {
            queryParams += `&businessLine=${businessLine}`
        }
        if (owner) {
            queryParams += `&owner=${owner}`
        }
        if (salesFrom) {
            queryParams += `&salesFrom=${salesFrom}`
        }
        if (salesTo) {
            queryParams += `&salesTo=${salesTo}`
        }
        const response = await axios.get(`${API_COMPANIES_URL}?${queryParams}`)
        return response.data
    }
)

export const checkBusinessLine = createAsyncThunk(
    "companies/checkBusinessLine",
    async (data, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_COMPANIES_URL}/check/businessLine/${data.businessLineId}/${data.branchId}`
        )
        return response.data
    }
)

export const storeCompany = createAsyncThunk(
    "companies/storeCompany",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_COMPANIES_URL}`,
            data
        )
        return response.data
    }
)

export const updateCompany = createAsyncThunk(
    "companies/updateCompany",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_COMPANIES_URL}/${data._id}`,
            data
        )
        return response.data
    }
)

export const getCompanyById = createAsyncThunk(
    "companies/getCompanyById",
    async (id, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_COMPANIES_URL}/byId/${id}`
        )
        return response.data
    }
)

export const updateCompanyImage = createAsyncThunk(
    "companies/updateCompanyImage",
    async (data, { dispatch, getState }) => {

        const formData = new FormData()
        formData.append('files', data.file)
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const response = await axios.post(
            `${API_COMPANIES_URL}/image/${data.id}/${data.section}/${data.type}`,
            formData,
            axiosConfig
        )
        return response.data
    }
)

export const increasePendingProducts = createAsyncThunk(
    "companies/increasePendingProducts",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_COMPANIES_URL}/increase/pending-products/${data._id}`,
            data
        )
        return response.data
    }
)

export const decreasePendingProducts = createAsyncThunk(
    "companies/decreasePendingProducts",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_COMPANIES_URL}/decrease/pending-products/${data._id}`,
            data
        )
        return response.data
    }
)

export const increasePendingPromotions = createAsyncThunk(
    "companies/increasePendingPromotions",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_COMPANIES_URL}/increase/pending-promotions/${data._id}`,
            data
        )
        return response.data
    }
)

export const decreasePendingPromotions = createAsyncThunk(
    "companies/decreasePendingPromotions",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_COMPANIES_URL}/decrease/pending-promotions/${data._id}`,
            data
        )
        return response.data
    }
)

export const getListCompaniesAsOptions = createAsyncThunk(
    "companies/getListCompaniesAsOptions",
    async ({
        page = 1,
        limit = 20,
        search,
        status,
        branch,
        noPaginate
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (status) {
            queryParams += `&status=${status}`
        }
        if (branch) {
            queryParams += `&branch=${branch}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        const response = await axios.get(`${API_COMPANIES_URL}?${queryParams}`)
        return response.data
    }
)

export const companiesSlice = createSlice({
    name: "companies",
    initialState: {
        list: [],
        totalPages: 1,
        loading: true,
        hasNextPage: false,
        pagingCounter: 0,
        totalDocs: 0
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListCompanies.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListCompanies.fulfilled, (state, action) => {
                const data = action.payload.docs || action.payload
                const sums = {
                    products: 0,
                    sales: 0,
                    commission: 0
                }
                data.map((el) => {
                    sums.products += el.totalProductsDelivered
                    sums.sales += el.totalSales
                    sums.commission += el.totalCommission
                    //return el
                })
                state.sumData = sums
                state.list = action.payload.docs || action.payload
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
    }
})

export default companiesSlice.reducer
