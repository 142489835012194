export const API_URL_PROD = "https://api.uffmarket.com/"
export const API_URL_DEV = "http://localhost:3001/"
export const RECAPTCHA_KEY = "6LfJnn4pAAAAANHiWfBfPThWBIYff4qNHeYuQX3V"
export const API_AUTH_URL = "auth"
export const API_LOGINS_URL = "logins"
export const API_BRANCHES_URL = "branches"
export const API_COUNTRIES_URL = "countries"
export const API_STATES_URL = "states"
export const API_CITIES_URL = "cities"
export const API_SECTIONS_URL = "sections"
export const API_LOCATIONS_URL = "locations"
export const API_BUSINESSLINES_URL = "businessLines"
export const API_GENERALS_URL = "generals"
export const API_USERS_URL = "users"
export const API_COMPANIES_URL = "companies"
export const API_ROLES_URL = "roles"
export const API_CLIENTS_URL = "clients"
export const API_ORDERS_URL = "orders"
export const API_COMPANY_ORDERS_URL = "companyOrders"
export const API_PRODUCTS_URL = "products"
export const API_GROUPS_URL = "groups"
export const API_VARIANTS_URL = "variants"
export const API_PROMOTIONS_URL = "promotions"
export const API_STATISTICS_URL = "statistics"
export const API_CASH_REGISTERS_URL = "cash-registers"
export const API_NOTIFICATIONS_URL = "notifications"