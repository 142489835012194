// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_BRANCHES_URL } from "../constants"

export const getListBranches = createAsyncThunk(
    "branches/getListBranches",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        const response = await axios.get(`${API_BRANCHES_URL}?${queryParams}`)
        return response.data
    }
)

export const getListBranchesAsOptions = createAsyncThunk(
    "branches/getListBranchesAsOptions",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        const response = await axios.get(`${API_BRANCHES_URL}?${queryParams}`)
        return response.data
    }
)

export const getBranchById = createAsyncThunk(
    "branches/getBranchById",
    async (id, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_BRANCHES_URL}/${id}`
        )
        return response.data
    }
)

export const storeBranch = createAsyncThunk(
    "branches/storeBranch",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_BRANCHES_URL}`,
            data
        )
        return response.data
    }
)

export const updateBranch = createAsyncThunk(
    "branches/updateBranch",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_BRANCHES_URL}/${data._id}`,
            data
        )
        return response.data
    }
)

export const attachPhotos = createAsyncThunk(
    "branches/attachPhotos",
    async (data, { dispatch, getState }) => {

        const formData = new FormData()
        formData.append('files', data.file[0])
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        const response = await axios.post(
            `${API_BRANCHES_URL}/upload/photos/${data.dataToSend.branchId}`,
            formData,
            axiosConfig
        )
        return response.data
    }
)

export const removePhoto = createAsyncThunk(
    "branches/removePhoto",
    async (data, { dispatch, getState }) => {
        const response = await axios.delete(
            `${API_BRANCHES_URL}/photos/remove/${data.branchId}/${data.index}`
        )
        return response.data
    }
)

export const branchesSlice = createSlice({
    name: "branches",
    initialState: {
        list: [],
        totalPages: 1,
        loading: true,
        hasNextPage: false,
        pagingCounter: 0,
        totalDocs: 0,
        branchName: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListBranches.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListBranches.fulfilled, (state, action) => {
                state.list = action.payload.docs || action.payload
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
    }
})

export default branchesSlice.reducer
