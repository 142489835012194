// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_COUNTRIES_URL } from "../constants"

export const getListCountries = createAsyncThunk(
  "countries/getListCountries",
  async ({
    page = 1,
    limit = 10,
    search
  } = {}) => {

    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }

    const response = await axios.get(`${API_COUNTRIES_URL}?${queryParams}`)
    return response.data
  }
)

export const countriesSlice = createSlice({
  name: "countries",
  initialState: {},
  reducers: {}
})

export default countriesSlice.reducer
