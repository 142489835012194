// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_CITIES_URL } from "../constants"

export const getListCities = createAsyncThunk(
    "cities/getListCities",
    async ({
        page = 1,
        limit = 10,
        search,
        stateId
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (stateId) {
            queryParams += `&stateId=${stateId}`
        }

        const response = await axios.get(`${API_CITIES_URL}?${queryParams}`)
        return response.data
    }
)

export const citiesSlice = createSlice({
    name: "cities",
    initialState: {},
    reducers: {}
})

export default citiesSlice.reducer
