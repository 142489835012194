// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_STATISTICS_URL } from "../constants"

export const getCounts = createAsyncThunk(
  "statistics/getCounts",
  async ({
    startDate,
    endDate,
    branches
  } = {}) => {

    let queryParams = `startDate=${startDate || new Date("1/1/24")}&endDate=${endDate || new Date()}`
    if (branches) {
      queryParams += `&branches=${branches}`
    }

    const response = await axios.get(`${API_STATISTICS_URL}?${queryParams}`)
    return response.data
  }
)

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState: {},
  reducers: {}
})

export default statisticsSlice.reducer
