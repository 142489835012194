// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_ROLES_URL } from "../constants"

export const getListRoles = createAsyncThunk(
  "roles/getListRoles",
  async ({
    page = 1,
    limit = 20,
    noPaginate,
    search,
    internal
  } = {}) => {

    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }

    if (noPaginate) {
      queryParams += `&noPaginate=true`
    }

    if (internal) {
      queryParams += `&internal=true`
    }

    const response = await axios.get(`${API_ROLES_URL}?${queryParams}`)
    return response.data
  }
)

export const rolesSlice = createSlice({
  name: "roles",
  initialState: {},
  reducers: {}
})

export default rolesSlice.reducer
