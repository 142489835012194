// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_GROUPS_URL } from "../constants"

export const getListGroups = createAsyncThunk(
    "groups/getListGroups",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate,
        company
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (company) {
            queryParams += `&company=${company}`
        }
        const response = await axios.get(`${API_GROUPS_URL}?${queryParams}`)
        return response.data
    }
)

export const getListGroupsSelect = createAsyncThunk(
    "groups/getListGroupsSelect",
    async ({
        page = 1,
        limit = 20,
        search,
        noPaginate,
        company
    } = {}) => {

        let queryParams = `page=${page}&limit=${limit}`
        if (search) {
            queryParams += `&search=${search}`
        }
        if (noPaginate) {
            queryParams += `&noPaginate=true`
        }
        if (company) {
            queryParams += `&company=${company}`
        }
        const response = await axios.get(`${API_GROUPS_URL}?${queryParams}`)
        return response.data
    }
)

export const getGroupById = createAsyncThunk(
    "groups/getGroupById",
    async (id, { dispatch, getState }) => {
        const response = await axios.get(
            `${API_GROUPS_URL}/${id}`
        )
        return response.data
    }
)

export const storeGroup = createAsyncThunk(
    "groups/storeGroup",
    async (data, { dispatch, getState }) => {
        const response = await axios.post(
            `${API_GROUPS_URL}`,
            data
        )
        return response.data
    }
)

export const updateGroup = createAsyncThunk(
    "groups/updateGroup",
    async (data, { dispatch, getState }) => {
        const response = await axios.put(
            `${API_GROUPS_URL}/${data._id}`,
            data
        )
        return response.data
    }
)

export const groupsSlice = createSlice({
    name: "groups",
    initialState: {
        list: [],
        totalPages: 1,
        loading: true,
        hasNextPage: false,
        pagingCounter: 0,
        totalDocs: 0
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getListGroups.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getListGroups.fulfilled, (state, action) => {
                state.list = action.payload.docs || action.payload
                state.totalPages = action.payload.totalPages
                state.hasNextPage = action.payload.hasNextPage
                state.pagingCounter = action.payload.pagingCounter
                state.totalDocs = action.payload.totalDocs
                state.loading = false
            })
    }
})

export default groupsSlice.reducer
